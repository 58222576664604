<template>
  <el-dialog
    model-value="dialogVisible"
    title="咨询详情"
    width="40%"
    @close="handleClose"
  >
    {{ props.viewDetailData }}
  </el-dialog>
</template>

<script setup>
import { defineEmits,ref ,defineProps,watch} from 'vue'
import axios from "@/util/axios";
import { ElMessage } from "element-plus";
import {getServerUrl} from "@/config/sys";

const props=defineProps({
  viewDetailData:{
    type: Object,
    default: {},
    required: true
  }
})


// const initViewDetailData=async(id)=>{
//   const res=await axios.get("views/getView?id="+id);
//   tableData.value=res.data.list;
// }


// watch(
//   ()=>props.viewDetailData,
//   ()=>{
//     console.log("id="+props.id);
//     let id=props.id;
//     if(id!=-1){
//       initViewDetailData(props.id);
//     }
//   },
//   {deep:true,immediate:true}
// )


const emits=defineEmits(['update:modelValue'])


const handleClose = () => {
  emits('update:modelValue',false)
}

const tableData=ref([
])




</script>

<style scoped>

</style>