<template>
  <el-card>
<!--    <el-row :gutter="20" class="header">-->
<!--      <el-col :span="7">-->
<!--        <el-input placeholder="请输入咨询内容..." clearable v-model="queryForm.query"></el-input>-->
<!--      </el-col>-->
<!--      <el-button type="primary" :icon="Search" @click="initViewsList">搜索</el-button>-->
<!--    </el-row>-->

    <el-table :data="tableData" height="550" stripe style="width: 100%">

      <el-table-column prop="id" label="id" width="50" fixed/>

      <el-table-column prop="title" label="标题" width="300" />

      <el-table-column prop="examine" label="标题图片" width="200" >
        <template v-slot="scope">
          <el-image
            style="width: 100px; height: 100px"
            :src="scope.row.imageUrl"
            ></el-image>
        </template>
      </el-table-column>

       <el-table-column prop="imageUrl" label="标题图片地址" width="200" />

       <el-table-column prop="details" label="链接地址" width="200" />

      <el-table-column prop="createTime" label="发布日期" width="100" />

<!--      <el-table-column prop="viewCount" label="浏览量" width="80" />-->

<!--      <el-table-column prop="remark" label="备注" width="60" />-->

      <el-table-column prop="action" label="操作" width="150" fixed="right">
        <template v-slot="scope">
          <el-button type="success" :icon="Edit"   @click="handleDialogValue(scope.row)">编辑</el-button>
<!--          <el-button type="danger" :icon="Delete" @click="handleDelete(scope.row.id)">删除</el-button>-->
        </template>

      </el-table-column>

    </el-table>


    <!--  编辑资讯对话框     -->
    <el-dialog title="编辑资讯" width="25%" center   v-model:visible="news_dialog_Visible" :model-value="news_dialog_Visible" @close="news_dialog_Visible = false">
      <!--  表单    -->
      <el-form ref="newsFormRef" :model="newsForm" label-width="100px">

        <el-form-item label="id">
          <el-input disabled v-model="newsForm.id"></el-input>
        </el-form-item>

        <el-form-item label="标题">
          <el-input type="textarea" v-model="newsForm.title"></el-input>
        </el-form-item>

        <el-form-item label="图片">
          <el-upload
            :action="uploadUrl"
            list-type="picture-card"
            :on-success="handleAvatarSuccess"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            limit="1"
          >
            <el-icon><Plus /></el-icon>
          </el-upload>
          <!--        <el-dialog :visible.sync="dialogVisible">-->
          <!--          <img width="100%" :src="dialogImageUrl" alt="">-->
          <!--        </el-dialog>-->
        </el-form-item>

        <el-form-item label="标题图片地址">
          <el-input type="textarea" v-model="newsForm.imageUrl"></el-input>
        </el-form-item>

        <el-form-item label="链接地址">
          <el-input type="textarea" v-model="newsForm.details"></el-input>
        </el-form-item>

<!--        <el-form-item label="发布时间">-->
<!--          <el-input  v-model="newsForm.createTime"></el-input>-->
<!--        </el-form-item>-->

        <el-form-item label="选择发布日期">
          <el-config-provider :locale="locale">
            <el-date-picker
              v-model="newsForm.createTime"
              type="date"
              placeholder="选择发布日期"

            >
            </el-date-picker>
          </el-config-provider>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="news_dialog_Visible = false">取 消</el-button>
          <el-button type="primary" @click="submit_edit">确 定</el-button>
        </el-form-item>

      </el-form>

<!--      <template v-slot:footer class="dialog-footer">-->
<!--        <el-button @click="news_dialog_Visible = false">取 消</el-button>-->
<!--        <el-button type="primary" @click="export1">确 定</el-button>-->
<!--      </template>-->
    </el-dialog>

    <el-pagination
      v-model:currentPage="queryForm.pageNum"
      :page-sizes="[10, 20, 30, 40,50]"
      :page-size="queryForm.pageSize"
      :small="small"
      :disabled="disabled"
      :background="background"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
  </el-card>

  <Dialog v-model="dialogVisible" :viewDetailData="viewDetailData"/>

</template>

<script setup>

import {Search,Edit,Delete } from '@element-plus/icons-vue'
import { ref } from 'vue'
import axios, { get2 } from "@/util/axios";
import {getBaseUrl} from '@/util/axios'
import {getServerUrl} from "@/config/sys";
import Dialog from './components/dialog'

import {ElMessageBox,ElMessage} from 'element-plus'

import zhCn from "element-plus/lib/locale/lang/zh-cn";
const locale=ref(zhCn)

const queryForm=ref({
  query:'',
  pageNum:1,
  pageSize:10,
  userId:111,


})

const total=ref(0)


const tableData=ref([
])

const id=ref(-1)
const viewDetailData=ref({})


const initViewsList=async()=>{
  // console.log('xxx')
  const res=await axios.get("views/list");
  console.log('资讯列表',res)

  tableData.value=res.data.data.viewsList;
  total.value=res.data.total;

//   获取baseUrl
  uploadUrl.value = getBaseUrl() + "views/uploadImage", // 上传的图片服务器地址
  console.log('图片上传接口为:',uploadUrl.value)

  initViewsList111()
}

initViewsList();


// const requestBody=ref(0)
const requestBody=ref({
  // name:'',
  // telephone:""
  // status:'',
})

const initViewsList111=async()=>{
  console.log('xxx')
  console.log('反馈意见列表')
  const res=await axios.get("opinion/list",requestBody.value);
  // const res=await axios.get2("opinion/list",{
  //   params: {
  //     requestBody: {
  //       // age: 25,
  //       // gender: 'male'
  //     }
  //   }
  // });
  // console.log('资讯列表',res)
  // const res=await axios.get("/opinion/list",);
  console.log('反馈意见列表',res)

  // tableData.value=res.data.data.viewsList;
  // total.value=res.data.total;
}





const dialogVisible=ref(false)



const handleSizeChange=(pageSize)=>{
  queryForm.value.pageNum=1;
  queryForm.value.pageSize=pageSize;
  initViewsList();
}

const handleCurrentChange=(pageNum)=>{
  queryForm.value.pageNum=pageNum;
  initViewsList();
}


const handleDialogValue = (row) => {
  // id.value=row.id;
  viewDetailData.value=row;
  // dialogVisible.value=true;
  console.log("row为：",row)
  console.log("row.id为：",row.id)
  console.log("newsForm为：",newsForm.value)
  console.log("tableData为：",tableData.value)

  for ( let i=0; i<tableData.value.length; i++){
    if( tableData.value[i].id == row.id){
      newsForm.value = tableData.value[i]
    }
  }

  // newsForm.value.id = row.id
  // newsForm.value = tableData.value
  console.log("newsForm为：",newsForm.value)
  console.log("tableData为：",tableData.value)

  news_dialog_Visible.value = true
}

const handleDelete = (id) => {

  ElMessageBox.confirm(
    '您确定要删除这条记录吗?',
    '系统提示',
    {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }
  )
    .then(async() => {
      console.log("id="+id)
      let res=await axios.del("views/removeById?id="+id);
      if(res.data.code==0){
        ElMessage({
          type: 'success',
          message: '删除成功！',
        });
        initViewsList();
      }else{
        ElMessage({
          type: 'error',
          message: res.data.msg,
        });
      }

    })
    .catch(() => {

    })
}

const handleOrderStatus = (id,status) => {
  ElMessageBox.confirm(
    '您确定要更新这个订单状态吗?',
    '系统提示',
    {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }
  )
    .then(async() => {

      let res=await axios.post("admin/order/updateStatus",{id:id,status:status});
      if(res.data.code==0){
        ElMessage({
          type: 'success',
          message: '执行成功！',
        });
        initViewsList();
      }else{
        ElMessage({
          type: 'error',
          message: res.data.msg,
        });
      }

    })
    .catch(() => {

    })
}


// 定义资讯表单
const news_dialog_Visible=ref(false)

const newsForm=ref({
  id:'',
  title:'',
  imageUrl:'',
  details:'',
  createTime:'',

})
// 提交编辑内容
const submit_edit=async()=>{
  // console.log('xxx')
  // getCurrentTime()
  console.log('选择发布时间',newsForm.value.createTime)
  console.log(msToDate(newsForm.value.createTime).withoutTime);//2021-08-15
  newsForm.value.createTime=msToDate(newsForm.value.createTime).withoutTime
  console.log('选择发布时间',newsForm.value.createTime)
  newsForm.value.createTime = newsForm.value.createTime.replaceAll('-','/')
  console.log('选择发布时间',newsForm.value.createTime)
  // newsForm.value.release_date = strArray[0]

  const res=await axios.post("views/update",newsForm.value);
  console.log('提交编辑内容',res)

  if(res.data.code==0){
    ElMessage.success("提交成功！");
    news_dialog_Visible.value = false

  }else{
    ElMessage.error("提交失败！请重试");
    news_dialog_Visible.value = false
  }

  initViewsList();

}

// 定义资讯表单
const extraimageUrl=ref('')
const uploadUrl=ref('')

// 图片上传成功钩子函数
const handleAvatarSuccess = (response, uploadFile) => {
  console.log('response:',response)
  console.log('uploadFile:',uploadFile)
  newsForm.value.imageUrl = response.data.src
}





const gettime=ref('')
const getCurrentTime= () =>{
  //获取当前时间并打印
  // let _this = this;
  let yy = new Date().getFullYear();
  let mm = new Date().getMonth()+1;
  let dd = new Date().getDate();
  let hh = new Date().getHours();
  let mf = new Date().getMinutes()<10 ? '0'+new Date().getMinutes() : new Date().getMinutes();
  let ss = new Date().getSeconds()<10 ? '0'+new Date().getSeconds() : new Date().getSeconds();
  // _this.gettime = yy+'/'+mm+'/'+dd+' '+hh+':'+mf+':'+ss;
  // console.log(_this.gettime)
  gettime.value = yy+'/'+mm+'/'+dd+' '+hh+':'+mf+':'+ss;
  console.log(gettime.value)
  let strArray = gettime.value.split(' ')
  console.log(strArray)
  newsForm.value.createTime = strArray[0]
}


//中国标准时间转日期
function msToDate (msec) {
  let datetime = new Date(msec);
  let year = datetime.getFullYear();
  let month = datetime.getMonth();
  let date = datetime.getDate();
  let hour = datetime.getHours();
  let minute = datetime.getMinutes();
  let second = datetime.getSeconds();

  let result1 = year +
    '-' +
    ((month + 1) >= 10 ? (month + 1) : '0' + (month + 1)) +
    '-' +
    ((date + 1) < 10 ? '0' + date : date) +
    ' ' +
    ((hour + 1) < 10 ? '0' + hour : hour) +
    ':' +
    ((minute + 1) < 10 ? '0' + minute : minute) +
    ':' +
    ((second + 1) < 10 ? '0' + second : second);

  let result2 = year +
    '-' +
    ((month + 1) >= 10 ? (month + 1) : '0' + (month + 1)) +
    '-' +
    ((date + 1) < 10 ? '0' + date : date);

  let result = {
    hasTime: result1,
    withoutTime: result2
  };

  return result;
}


</script>

<style lang="scss" scoped>

.header{
  padding-bottom: 16px;
  box-sizing: border-box;
}

.el-pagination{
  padding-top: 15px;
  box-sizing: border-box;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}


</style>
